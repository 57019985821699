import React, { useEffect, useState } from "react";
import MultipleInputFile from "../../components/Input/MultipleInputFile";
import FormLayout from "./FormLayout";
import { db } from "../../firebase";
import { doc } from "firebase/firestore";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import queries from "../../queries";

function Form(props) {
  const {
    collectionRef,
    updateTo = "",
    deleteObj = null,
    queryKey = "",
    defaultVal = [],
    setIsError,
    setNotificationOpen,
    setNotificationMessage,
  } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [slidersType, setSlidersType] = useState(
    defaultVal ? defaultVal.sliders : "carousel"
  );

  const [elemDoc, setElemDoc] = useState(
    defaultVal && updateTo && doc(db, updateTo, defaultVal.id)
  );

  const queryClient = useQueryClient();
  const mutationCreate = useMutation((ref, newItem) =>
    queries?.post(ref, newItem)
  );
  const mutationUpdate = useMutation((elemDoc, updatedItem) =>
    queries?.put(elemDoc, updatedItem)
  );
  const mutationDelete = useMutation((elemDoc) => queries?.delete(elemDoc));

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const orginalFormData = new FormData(e.target);
    let inputObject = Object.fromEntries(orginalFormData);
    if (inputObject.images) {
      inputObject.images = files;
    }

    console.log(inputObject, files);

    queryClient.invalidateQueries(queryKey);

    if (defaultVal.id) {
      inputObject.id = defaultVal.id;
      mutationUpdate.mutateAsync({ elemDoc, data: inputObject });
      setNotificationMessage("Update réussi ! 😎");
    } else {
      mutationCreate.mutateAsync({ collectionRef, data: inputObject });
      setNotificationMessage("Création réussi ! la classe 😎");
    }
    setIsLoading(false);
    setSidebarOpen(false);
    setNotificationOpen(true);
  };

  const deleteItem = async () => {
    if (window.confirm("T'es sûre que tu veux supprimer cet élément ?")) {
      const thatMyBoy = doc(db, updateTo, defaultVal.id)
      try {
        mutationDelete.mutate(thatMyBoy);
        setNotificationMessage("Tout est bien supprimé !");
        setNotificationOpen(true);
        queryClient.invalidateQueries(queryKey);
      } catch (e) {
        setNotificationMessage("Et merde, il y a un soucis appel moi !");
        setNotificationOpen(true);
        setIsError(true);
        console.error(e);
      }
    }
  };

  if (deleteObj) {
    return (
      <button type="button" onClick={() => deleteItem(defaultVal.id)}>
        <span className="sr-only">Delete</span>
        <TrashIcon className="w-5" />
      </button>
    );
  }

  return (
    <FormLayout
      id={defaultVal.id && defaultVal.id}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    >
      <form
        className=" overflow-hidden w-full max-w-3xl space-y-4 lg:space-y-8 flex flex-col items-center px-4 my-8 mx-auto"
        onSubmit={submit}
      >
        <div className="w-full">
          <label
            htmlFor="titre"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Titre
          </label>
          <input
            required
            type="text"
            id="titre"
            placeholder="Tu tape le titre ici hein"
            name="titre"
            defaultValue={defaultVal && defaultVal.titre}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Description
          </label>
          <textarea
            required
            id="description"
            rows="4"
            name="description"
            defaultValue={defaultVal && defaultVal.description}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:outline-none border-gray-300 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-teal-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
            placeholder="Write your thoughts here..."
          ></textarea>
        </div>
        <fieldset className=" w-full flex gap-4">
          <legend className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Sliders type
          </legend>
          <div className="flex items-center mb-2">
            <input
              required
              id="slider-option-1"
              type="radio"
              name="sliders"
              value="carousel"
              defaultChecked={defaultVal && defaultVal.sliders === "carousel"}
              onChange={(e) => setSlidersType(e.target.value)}
              className="cursor-pointer w-4 h-4 border-gray-300  dark:focus:bg-teal-600 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="slider-option-1"
              className="cursor-pointer block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Carousel
            </label>
          </div>
          <div className="flex items-center mb-2">
            <input
              required
              id="slider-option-2"
              type="radio"
              name="sliders"
              onChange={(e) => setSlidersType(e.target.value)}
              value="compare slider"
              defaultChecked={
                defaultVal && defaultVal.sliders === "compare slider"
              }
              className="cursor-pointer w-4 h-4 border-gray-300 focus:ring-2 focus:ring-teal-300 text-teal-500 dark:focus:ring-teal-600 dark:focus:bg-teal-600 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="slider-option-2"
              className="cursor-pointer block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Comparaison slider
            </label>
          </div>
        </fieldset>
        <MultipleInputFile
          label="Images"
          name="images"
          defaultValue={defaultVal && defaultVal.images}
          setFile={setFiles}
          limit={slidersType !== "carousel" ? 2 : 10}
        />
        <button
          type="submit"
          className="py-2.5 px-5 mr-2text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-teal-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}{" "}
          Submit
        </button>
      </form>
    </FormLayout>
  );
}

export default Form;
