import React from "react";
import Table from "../components/Table/Table";
import { db } from "../firebase/index";
import { collection } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";
import queries from "../queries";

function DashBoard() {
  const creationRef = collection(db, "creations");
  const entretientRef = collection(db, "entretients");
  const elagageRef = collection(db, "elagages");

  const { isLoadingCrea, data: creation } = useQuery(["creations"], () =>
    queries.getAll(creationRef).then((res) => res)
  );

  const { isLoadingEntr, data: entretient } = useQuery(["entretients"], () =>
    queries.getAll(entretientRef).then((res) => res)
  );

  const { isLoadingEla, data: elagage } = useQuery(["elagages"], () =>
    queries.getAll(elagageRef).then((res) => res)
  );

  return (
    <div className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">DashBoard</h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {isLoadingCrea && isLoadingEla && isLoadingEntr ? (
          <p>Attend un peu</p>
        ) : (creation?.length > 1 ||
          entretient?.length > 1 ||
          elagage?.length > 1 ? (
          <Table
            hideEdit={true}
            title1="Creation"
            data={creation}
            title2="Entretient"
            data2={entretient}
            title3="Elagage"
            data3={elagage}
          />
        ) : (
          <div>Y'a R , frére</div>
        ))}
      </div>
    </div>
  );
}

export default DashBoard;
