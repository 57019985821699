import React from "react";
import Form from "../../layouts/Form/Form";
import { useNavigate } from "react-router-dom";

const Table = (props) => {
  const {
    data = [],
    data2 = [],
    data3 = [],
    title1 = undefined,
    title2 = undefined,
    title3 = undefined,
    updateTo,
    queryKey = "",
    setNotificationMessage,
    setIsError,
    setNotificationOpen,
    hideEdit = false,
  } = props;

  const navigation = useNavigate();

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pointer-events-auto">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-4 py-3 border-l">
              Titre
            </th>
            <th scope="col" className="px-4 py-3 border-l w-full">
              Description
            </th>
            {!hideEdit && (
              <th scope="col" className="px-4 py-3 border-l w-16">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {title1 !== undefined && data.length > 0 && (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <th
                colSpan={2 + (hideEdit ? 0 : 1)}
                scope="colgroup"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white cursor-pointer"
                onClick={() => navigation("/" + title1)}
              >
                {title1}
              </th>
            </tr>
          )}
          {data.length > 0 &&
            data?.map((obj, index) => (
              <tr
                key={`creation_${index}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {obj?.titre}
                </th>
                <td className="px-4 py-4 border-l">
                  <p className="line-clamp-1">{obj?.description}</p>
                </td>
                {!hideEdit && (
                  <td className="px-4 py-4 border-l flex gap-2">
                    <Form
                      defaultVal={obj}
                      queryKey={queryKey}
                      queryId={index}
                      updateTo={updateTo}
                      setNotificationMessage={setNotificationMessage}
                      setIsError={setIsError}
                      setNotificationOpen={setNotificationOpen}
                    />
                    <Form
                      defaultVal={obj}
                      queryKey={queryKey}
                      updateTo={updateTo}
                      setNotificationMessage={setNotificationMessage}
                      setIsError={setIsError}
                      setNotificationOpen={setNotificationOpen}
                      deleteObj
                    />
                  </td>
                )}
              </tr>
            ))}
          {title2 !== undefined && data2.length > 0 && (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <th
                colSpan={2 + (hideEdit ? 0 : 1)}
                scope="colgroup"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white cursor-pointer"
                onClick={() => navigation("/" + title2)}
              >
                {title2}
              </th>
            </tr>
          )}
          {data2.length > 0 &&
            data2?.map((obj, index) => (
              <tr
                key={`creation_${index}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {obj?.titre}
                </th>
                <td className="px-4 py-4 border-l">{obj?.description}</td>
              </tr>
            ))}
          {title3 !== undefined && data3.length > 0 && (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <th
                colSpan={2 + (hideEdit ? 0 : 1)}
                scope="colgroup"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white cursor-pointer"
                onClick={() => navigation("/" + title3)}
              >
                {title3}
              </th>
            </tr>
          )}
          {data3.length > 0 &&
            data3?.map((obj, index) => (
              <tr
                key={`creation_${index}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="px-4 py-4  border-l font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {obj?.titre}
                </th>
                <td className="px-4 py-4 border-l">{obj?.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
