import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon, CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect } from "react";
const Notification = (props) => {
  const { isError = false, message = "", open, setOpen } = props;

  // const [open, setOpen] = useState(true)
  useEffect(() => {
    setTimeout(() => setOpen(false), 4500);
  });
  return (
    <Transition.Root show={open} as={Fragment}>
      <Popover
        as="div"
        className="z-50 absolute top-5 right-5"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full opacity-0"
          enterTo="-translate-x-0 opacity-100"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="-translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          {isError ? (
            <Popover.Panel
              className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-2 py-2 shadow-md"
              role="alert"
            >
              <div className="flex gap-4 items-center">
                <div className="py-1">
                  <ExclamationTriangleIcon className="w-5 h-5" />
                </div>
                <div>
                  {message}
                </div>
                <XMarkIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
            </Popover.Panel>
          ) : (
            <Popover.Panel
              className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-2 py-2 shadow-md"
              role="alert"
            >
              <div className="flex gap-4 items-center">
                <div className="py-1">
                  <CheckIcon className="w-5 h-5" />
                </div>
                <div>
                  {message}
                </div>
                <XMarkIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
            </Popover.Panel>
          )}
        </Transition.Child>
      </Popover>
    </Transition.Root>
  );
};

export default Notification;
