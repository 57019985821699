import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Sidebar from "./layouts/Sidebar/Sidebar";
import Page from "./pages/Page";
import DashBoard from "./pages/DashBoard";
import { createBrowserRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context/userContext";
import Auth from "./pages/Auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,
  },
  {
    path: "/creation",
    element: <Page queryKey={"creations"} />,
  },
  {
    path: "/entretient",
    element: <Page queryKey={"entretiens"} />,
  },
  {
    path: "/elagage",
    element: <Page queryKey={"elagages"} />,
  },
]);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <UserContextProvider>
          <Auth>
            <Sidebar>
              <Routes>
                <Route path={"/"} element={<DashBoard />} />
                <Route
                  path={"/creation"}
                  element={<Page queryKey={"creations"} />}
                />
                <Route
                  path={"/entretient"}
                  element={<Page queryKey={"entretients"} />}
                />
                <Route
                  path={"/elagage"}
                  element={<Page queryKey={"elagages"} />}
                />
              </Routes>
            </Sidebar>
          </Auth>
        </UserContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
