import { useState, isValidElement } from 'react';
import BtnCarousel from './BtnCarousel';

const mod = (n, m) => ((n % m) + m) % m;

const Carousel = (props) => {
  const {
    data = [],
    arrowPos = 'center',
    hideDot = false,
    defaultIndex = 0,
    isControled = false,
    setIndex: setIndexControled = () => {},
    index: indexControled = 0
  } = props;

  // Local state
  const [slideIndex, setSlideIndex] = useState(defaultIndex);

  // Choose local or props statement
  const index = isControled ? indexControled : slideIndex;
  const setIndex = isControled ? setIndexControled : setSlideIndex;

  const nextSlide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIndex(mod(index + 1, data.length));
  };

  const prevSlide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIndex(mod(index - 1, data.length));
  };

  return (
    <div className="w-full max-w-[700px] h-full max-h-[500px] m-auto relative overflow-hidden">
      {data.map((obj, idx) => {
        return (
          <div
            key={'test_' + idx}
            className={`w-full h-full absolute transition  ${
              index === idx ? 'opacity-100' : 'opacity-0'
            }`}>
            {!isValidElement(obj) ? <img className="h-full w-full object-cover" alt='La rose des ventsimage - admin' src={obj} /> : obj}
          </div>
        );
      })}

      <BtnCarousel
        nextSlide={(e) => nextSlide(e)}
        prevSlide={(e) => prevSlide(e)}
        arrowPos={arrowPos}
      />

      {hideDot ? null : (
        <div className="absolute left-1/2 -translate-x-1/2 bottom-2 flex gap-1 group">
          {Array.from({ length: data.length }).map((_, idx) => (
            <div
              key={'testDot_' + idx}
              onClick={() => setIndex(idx)}
              className={`w-3 h-3 rounded-full border-2 ${
                index === idx ? 'bg-black' : 'bg-white'
              }`}></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
