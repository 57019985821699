import React, { useContext } from "react";
import { UserContext } from "../context/userContext";
import logo from "../assets/logo-minimal-horizontale.svg";
const Auth = (props) => {
  const { children } = props;
  const { currentUser, SignInFunc, errorAuth } = useContext(UserContext);

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const orginalFormData = new FormData(e.target);
    let inputObject = Object.fromEntries(orginalFormData);
    SignInFunc(inputObject.password);
  };

  if (currentUser) {
    return children;
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-50/50 dark:bg-gray-900">
      <div className="overflow-hidden shadow rounded-lg divide-y bg-white border dark:bg-gray-800/50 dark:border-gray-700 divide-gray-200  dark:divide-gray-800">
        <div className="py-1 px-3 flex items-center min-w-[20rem]">
          <img
            className="h-16 w-auto"
            src={logo}
            alt="La rose des vents admin"
          />
        </div>
        <form
          onSubmit={submit}
          className="px-4 py-6 sm:p-6 flex flex-col gap-4"
        >
          {errorAuth && (
            <div color="red" className="mb-4">
              Le mot de passe ne semble pas bon coco.
            </div>
          )}
          <div className="w-full">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Envoie le mdp bébé !"
              name="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
            />
          </div>
          <div className="flex flex-col sm:flex-row-reverse ">
            <button
              type="submit"
              className="py-1.5 px-2 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-teal-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;
