import { useState } from "react";
import Table from "../components/Table/Table";
import Form from "../layouts/Form/Form";
import Notification from "../components/Notification";
import { db } from "../firebase/index";
import { collection, doc } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";
import queries from "../queries";

const Page = (props) => {
  const { queryKey } = props;
  const creationRef = collection(db, queryKey);
  const docRef = doc(db, queryKey, queryKey);
  const [ notificationOpen, setNotificationOpen ] = useState(false)
  const [ notificationMessage, setNotificationMessage ] = useState(false)
  const [ isError, setIsError ] = useState(false)

  const { isLoadingData, data } = useQuery([queryKey], () =>
    queries.getAll(creationRef).then((res) => res)
  );

  const capitalize = (val) => val[0].toUpperCase() + val.slice(1, val.length).toLowerCase();

  return (
    <div className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">{capitalize(queryKey)}</h1>
        <Notification  open={notificationOpen} setOpen={setNotificationOpen} message={notificationMessage} isError={isError}  />
        <Form collectionRef={creationRef} queryKey={queryKey} setNotificationMessage={setNotificationMessage} setIsError={setIsError} setNotificationOpen={setNotificationOpen} />
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {isLoadingData ? (
          <p>Attend un peu</p>
        ) : (
          data?.length > 0 ?
          <Table
            data={data}
            updateTo={"/" + queryKey}
            queryKey={queryKey}
            setNotificationMessage={setNotificationMessage} 
            setIsError={setIsError} 
            setNotificationOpen={setNotificationOpen}
          />:
          <div>
            Y'a R , frére
          </div>
        )}
      </div>
    </div>
  );
};

export default Page;
