import { addDoc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const handlePost = async (collectionRef, data, val) => {
  let images = [];

  const storage = getStorage();
  let count = 0;

  for (let i = 0; i < data.images.length; i++) {
    const storageRef = ref(storage, "images/" + data.images[i].imgName);
    const uploadTask = uploadBytesResumable(storageRef, data.images[i].imgUrl);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log(snapshot);
      },
      (error) => error,
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          data.images[i].imgUrl = downloadURL;

          data.images = data.images.filter((o) => typeof o.imgUrl === 'string');

          if (count === 0) {
            addDoc(collectionRef, data)
              .then((docRef) => {
                console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
              })
              .catch((error) => {
                console.log(error);
              });
            count++;
          } else updateDoc(collectionRef, data);
        });
      }
    );
  }

  //   console.log('data', data, images);

  //   if ("post" === val && data.images.length === images.length) {
  //       for (let i = 0; i < images.length; i++) {
  //         data.images[i].url = images[i];
  //       }
  //       await addDoc(collectionRef, data);
  //     return data;
  //   } else if ("update" === val) {
  //     await updateDoc(collectionRef, data);
  //     return data;
  //   }
};
const getQuery = async (ref) => {
  try {
    const data = await getDocs(ref);
    return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  } catch (error) {
    return error;
  }
};
const deleteQuery =  (itemDoc) => {
  try {
    deleteDoc(itemDoc);
  } catch (error) {
    return error;
  }
};

const postQuery = (data) => addDoc(data.collectionRef, data.data);
const putQuery = async (data) => {
  await updateDoc(data.collectionRef, data.data);
};

const exportage = {
  getAll: async (ref) => await getQuery(ref),
  post: async (data) => await postQuery(data),
  put: async (data) => await putQuery(data),
  delete: async (elemDoc) => await deleteQuery(elemDoc),
};

export default exportage;
